import ApplicationController from './application_controller'

export default class extends ApplicationController {
  sort(event) {
    let element = document.getElementById("contents-list");
    let contentElements = document.getElementsByClassName("content-item");
    let contents = Array.from(contentElements).map((content, index) => {
      return { id: content.dataset.contentId, position: (index +1 ) }
    });

    this.stimulate('GroupsReflex#sort', contents);
  }

}
